<template>
  <div id="order-modal">
    <div class="order-modal-container">
      <div class="order-modal__background-image">
        <img :src="data.crypto_image" alt="" />
      </div>
      <div class="order-modal__close-icon" @click.prevent="closeModal">
        <img
          src="../../assets/images/close-icon.svg"
          alt=""
          class="close-icon"
        />
      </div>
      <div class="order-modal__content-wrapper">
        <div class="order-modal__content-header">
          جزئیات سفارش
        </div>
        <div class="order-modal__content-title">
          {{ handleType[data.type].name }} {{ data.crypto_persian_name }}
        </div>
        <div class="order-modal__content-details-wrapper">
          <div class="order-modal__details-column">
            <div class="details">
              <span class="details-title">شماره سفارش:</span
              ><span class="order-number">{{ data.order_number }}</span>
            </div>
            <div class="details">
              <span class="details-title">تاریخ ثبت:</span
              ><span>{{ $G2J(data.created_at_date) }}</span>
            </div>
            <div class="details">
              <span class="details-title">کد ووچر:</span
              ><span>{{ data.voucher_code ? data.voucher_code : "----" }}</span>
            </div>
          </div>
          <div class="order-modal__details-column">
            <div class="details">
              <span class="details-title">مبلغ سفارش:</span
              ><span
                >{{ data.cost ? Number(data.cost).toLocaleString() : 0
                }}<span>تومان</span></span
              >
            </div>
            <div class="details">
              <span class="details-title">مشخصات مشتری:</span
              ><span>{{ data.first_name + " " + data.last_name }}</span>
            </div>
            <div class="details">
              <span class="details-title">کد فعال سازی:</span
              ><span>{{
                data.activation_code ? data.activation_code : "----"
              }}</span>
            </div>
          </div>
          <div class="order-modal__details-column">
            <div class="details">
              <span class="details-title">مبلغ پرداخت شده:</span
              ><span
                >{{ data.cost ? Number(data.cost).toLocaleString() : 0
                }}<span>تومان</span></span
              >
            </div>
            <div class="details">
              <span class="details-title">واریز شده به حساب:</span
              ><span>
                {{
                  data.card_number || data.other_card_number
                    ? data.card_number || data.other_card_number
                    : "-----"
                }}</span
              >
            </div>
            <div class="details">
              <span class="details-title">قیمت واحد</span
              ><span>
                {{
                  data.crypto_unit_fee
                    ? Number(data.crypto_unit_fee).toLocaleString()
                    : 0
                }}$</span
              >
            </div>
          </div>
        </div>
        <div class="button-wrapper" @click.prevent="closeModal">
          <button class="button-element">متوجه شدم</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../Utility/georgianToJalali";

export default {
  name: "OrderModal",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: true,
      handleType: {
        buy: { name: "خرید" },
        sell: { name: "فروش" },
        charge: { name: "شارژ" },
        product_pruchase: { name: "خرید محصول" }
      }
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
#order-modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-modal-container {
  min-height: 440px;
  max-width: 930px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 15px 15px 20px 15px;
  position: relative;
  /*background: url("../../assets/images/perfect-money-modal-background.svg") no-repeat center rgba(255, 255, 255, .2);*/
}

.order-modal__close-icon {
  cursor: pointer;
}

.order-modal__content-wrapper {
  padding: 30px 20px 0 0;
  z-index: 100000;
  position: relative;
}

.order-modal__content-header {
  font-weight: 500;
  /*font-size: 22px;*/
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 25px;
  font-family: "Vazir Medium FD";
}

.order-modal__content-title {
  font-weight: 500;
  /*font-size: 20px;*/
  font-size: 16px;
  line-height: 31px;
  margin-bottom: 18px;
  font-family: "Vazir Medium FD";
}

.order-modal__content-details-wrapper {
  display: flex;
  /*gap: 60px;*/
  gap: 20px;
  flex-wrap: wrap;
}

.order-modal__background-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0.2;
}

.order-modal__details-column {
  display: flex;
  flex-direction: column;
}
.order-modal__details-column {
  display: flex;
  /*gap: 40px;*/
  gap: 20px;
  flex-wrap: wrap;
}

.details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  font-family: "Vazir Medium FD";
}

.order-number {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #1f3c88;
}

.button-wrapper {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  z-index: 10000;
}

.button-element {
  padding: 4px 40px;
  background: #1f3c88;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
}

@media (min-width: 720px) {
  .order-modal__content-header {
    font-size: 22px;
  }

  .order-modal__content-title {
    font-size: 20px;
  }
}
</style>
