<template>
  <div class="sort-table" :class="{ maxHeight: maxHeight }">
    <OrderModal v-if="modal.show" @close="closeModal" :data="modal.data" />
    <table>
      <tr class="header">
        <th>
          <span>شماره سفارش</span>
          <span><img src="../../assets/images/gg_sort-az.svg" alt=""/></span>
        </th>
        <th>
          <span>نام ارز</span>
          <span
            ><img src="../../assets/images/ic_round-arrow-drop-down.svg" alt=""
          /></span>
        </th>
        <th>نوع سفارش</th>
        <th>
          <span>مقدار</span>
          <span><img src="../../assets/images/gg_sort-az.svg" alt=""/></span>
        </th>
        <th>
          <span>زمان</span>
          <span
            ><img src="../../assets/images/ic_round-arrow-drop-down.svg" alt=""
          /></span>
        </th>
        <th>وضعیت</th>
        <th>
          <span>مبلغ سفارش</span>
          <span><img src="../../assets/images/gg_sort-az.svg" alt=""/></span>
        </th>
        <th>جزيیات</th>
      </tr>
      <tr v-for="item in orders" :key="item.id">
        <td>{{ item.order_number }}</td>
        <td>
          <span><img class="item-image" :src="item.crypto_image" alt=""/></span>
          <span class="item-name">{{ item.crypto_english_name }}</span>
        </td>
        <td>{{ handleOrderType[item.type].name }}</td>
        <td>{{ item.amount }} $</td>
        <td>{{ $G2J(item.created_at_date) }}</td>
        <td class="green" :style="{ color: handleStatus[item.status].color }">
          {{ handleStatus[item.status].name }}
        </td>
        <td>
          <span>{{ item ? Number(item.cost).toLocaleString() : 0 }}</span>
          تومان
        </td>
        <td>
          <img
            @click.prevent="shoModal(item)"
            style="cursor: pointer"
            class="action-image"
            src="../../assets/images/carbon_view.svg"
            alt=""
          />
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import OrderModal from "@/components/Modals/OrderModal";
import "../../Utility/georgianToJalali";

export default {
  components: { OrderModal },
  props: {
    orders: {
      type: Array,
      required: true
    },
    maxHeight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: {
        show: false,
        data: {}
      },
      handleOrderType: {
        buy: { name: "خرید" },
        sell: { name: "فروش" },
        charge: { name: "شارژ" },
        product_pruchase: { name: "خرید محصول" }
      },
      handleStatus: {
        processing: { color: "#FB9224", name: "در حال بررسی" },
        confirm: { color: "#039100", name: "موفق" },
        reject: { color: "#DC0000", name: "ناموفق" }
      }
    };
  },
  methods: {
    shoModal(item) {
      this.modal.show = true;
      this.modal.data = item;
    },
    closeModal() {
      this.modal.show = false;
    }
  }
};
</script>
<style scoped>
.sort-table {
  /*min-width: 1110px;*/
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: auto;
}

.maxHeight {
  max-height: 303px;
}

table {
  width: 100%;
  border: none;
  min-width: 1110px;
  border-spacing: 0;
}

th {
  padding: 10px 0;
}

tr {
  /*height: 70px;*/
}

td {
  text-align: center;
  padding: 10px 0;
  /*height: 47px;*/
}

img {
  vertical-align: middle;
}

.header {
  background: rgba(31, 60, 136, 0.1);
  /*height: 45px;*/
}

span {
  vertical-align: middle;
}

.item-image {
  width: 40px;
  height: 40px;
}

.action-image {
  width: 30px;
  height: 30px;
}

.item-name {
  margin-right: 10px;
}

.green {
  color: #039100;
}

.red {
  color: #dc0000;
}
</style>
