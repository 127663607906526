<template>
  <div id="orders">
    <loading :can-cancel="true"></loading>
    <div class="orders-header">
      <PanelHeader title="سفارشات" />
    </div>
    <div class="orders-container">
      <div class="orders-table-wrapper">
        <SortTable :orders="orders" />
      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import SortTable from "@/components/Table/SortTable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  components: { SortTable, PanelHeader, Loading },
  data() {
    return {
      orders: []
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      let loading = this.$loading.show();
      try {
        let response = await axios("/panel/order");
        this.orders = response.data;
      } catch (error) {
        console.log(error);
      }
      loading.hide();
    }
  }
};
</script>

<style scoped>
#orders {
  width: 100%;
  /*width: calc(100% - 250px);*/
  /*margin-right: auto;*/
}

.orders-container {
  margin-top: 20px;
  /*padding: 0 40px;*/
  padding: 0 10px;
  height: 100vh;
}

@media (min-width: 1140px) {
  #orders {
    width: calc(100% - 250px);
    margin-right: auto;
  }

  .orders-container {
    padding: 0 40px;
  }
}
</style>
